<template>
  <t-modal v-model="showModal" size="sm" @ok="unlatch()" ok-title="unlatch">
    <div style="min-width:350px;min-height:400px;">
    <h5>Latch Info</h5>
    <hr/>
    <span class="text-muted float-right">Campaign</span>
    <span v-for="(latchVal, latchKey) in latches[0]" :key="`latchKey1-${latchKey}`">
      <template v-if="latchKey === 'metaJson'">
        <span v-for="(itemsVal, itemsKey) in latchVal" :key="`latchKey2-${itemsKey}`">
          <strong>{{$filters.convertFromCamelCase(itemsKey)}}</strong>: {{itemsVal}}<br/>
        </span>
      </template>
      <template v-else>
        <strong>{{$filters.convertFromCamelCase(latchKey)}}</strong>: {{latchVal}}<br/>
      </template>
    </span>
    <hr/>
    <span class="text-muted float-right">Account</span>
    <span v-for="(latchVal, latchKey) in latchData.settingValue" :key="`latchKey3-${latchKey}`">
      <template v-if="latchKey === 'itemsToUpdate'">
        <span v-for="(itemsVal, itemsKey) in latchVal" :key="`latchKey4-${itemsKey}`">
          <strong>{{$filters.convertFromCamelCase(itemsKey)}}</strong>: {{itemsVal}}<br/>
        </span>
      </template>
      <template v-else>
        <strong>{{$filters.convertFromCamelCase(latchKey)}}</strong>: {{latchVal}}<br/>
      </template>
    </span>
  </div>
  </t-modal>
</template>

<script>
export default {
  name: 'latchInfo2',
  props: ['accountPlanId', 'campaignPlanId', 'latches', 'show'],
  data () {
    return {
      latchData: {}
    }
  },
  watch: {
    accountPlanId: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getLatchData()
        }
      }
    }
  },
  computed: {
    showModal: {
      get() {
        return this.show
      },
      set(x) {
        this.$emit('update:show', x)
      }
    }
  },
  methods: {
    async getLatchData () {
      const ret = await this.$res.fetch.accountSettings({ accountPlanId: this.accountPlanId, settingKey: 'TICKETING' })
      this.$setCompat(this, 'latchData', ret)
    },
    async unlatch () {
      const ret = await this.$res.fetch.unlatch({ accountPlanId: this.accountPlanId, campaignPlanId: this.campaignPlanId })
      if (ret) {
        this.$toast('Unlatched', 'success')
      }
    }
  }
}
</script>

<style scoped>

</style>
