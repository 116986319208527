<template>
  <div>
    <t-button-inline v-p-tooltip.top="'Quick Create Promoted Post'"
                     severity="secondary" size="small" class="mx-1"
                     ref="quickCreateButton"
                     @click="openPostTable">
      <fluency-icon type="promotePost"/>
    </t-button-inline>

    <t-modal v-if="showModal"
             v-model="showModal"
             size="xl"
             centered
             no-close-on-backdrop
             :ok-disabled="selectedPosts.length === 0 || !quickCreateCampaign || !quickCreateAdSet || (requireCta && !quickCreateCta)"
             @ok="promoteSelectedPosts(selectedPosts)"
             @cancel="cancelQuickCreate">

      <template #modal-header>
        <div class="d-flex justify-content-between flex-grow-1">

          <promoted-post-campaign-ad-set-chooser class="w-50 pr-3"
                                               :account-plan-id="accountPlanId"
                                               @selected="quickCreateCampaign = $event.campaign; quickCreateAdSet = $event.adSet"/>
          <promoted-post-cta v-if="requireCta" class="w-50 pl-3"
                             :primary-domain="primaryDomain"
                             @input="quickCreateCta = $event"></promoted-post-cta>
<!--          <div v-else class="d-flex flex-grow-1">-->
<!--            <div>-->
<!--              <h3>{{ quickCreateCampaign.campaignName }}</h3>-->
<!--              <h4 class="ml-5">{{ quickCreateAdSet.adGroupName }}</h4>-->
<!--            </div>-->
<!--            <b-button variant="link" class="align-self-start ml-5 p-0 pt-2"-->
<!--                      @click="quickCreateCampaign = null; quickCreateAdSet = null;">Change-->
<!--            </b-button>-->
<!--          </div>-->
          <b-button variant="link" class="text-secondary align-self-start" @click="cancelQuickCreate"><fluency-icon type="x" /></b-button>
        </div>
      </template>
      <facebook-post-list-data-table v-if="showModal"
                                     :account-plan-id="accountPlanId"
                                     select-mode
                                     @selected="selectedPosts.splice(0, selectedPosts.length, ...$event)"/>

      <template #modal-ok>
        <fluency-icon type="lightningBolt"/>
        Promote Post{{ selectedPosts.length === 1 ? '' : 's' }}
        {{ selectedPosts.length > 0 ? `(${selectedPosts.length})` : '' }}
      </template>
    </t-modal>
  </div>
</template>

<script>
import PromotedPostCampaignAdSetChooser from '@/components/common/promotedPostCampaignAdSetChooser'
import FacebookPostListDataTable from '@/components/common/facebookPostListDataTable'
import PromotedPostCta from '@/components/promotedPostCta'

export default {
  name: 'quickCreatePromotedPost',
  components: { PromotedPostCta, FacebookPostListDataTable, PromotedPostCampaignAdSetChooser },
  props: {
    accountPlanId: [Number, String],
    primaryDomain: String
  },
  data () {
    return {
      quickCreateCampaign: null,
      quickCreateAdSet: null,
      quickCreateCta: null,
      showModal: false,
      selectedPosts: []
    }
  },
  computed: {
    requireCta () {
      return this.quickCreateCampaign && this.quickCreateCampaign.goal !== 'POST_ENGAGEMENT'
    }
  },
  methods: {
    openPostTable () {
      this.showModal = true
    },
    async promoteSelectedPosts (posts = []) {
      const promotedPosts = this.requireCta ? posts.map(p => ({ ...p, callToAction: this.quickCreateCta, attachments: JSON.stringify(p.attachments) })) : posts.map(p => ({ ...p, attachments: JSON.stringify(p.attachments) }))
      const response = await this.$res.set.quickPromotePosts({
        accountPlanId: this.accountPlanId,
        adGroupPlanId: this.quickCreateAdSet.adGroupPlanId,
        promotedPosts
      })
      if (response) {
        this.$toast(`${promotedPosts.length} post${promotedPosts.length > 1 ? 's' : ''} successfully promoted`, 'success')
      }
    },
    cancelQuickCreate () {
      this.showModal = false
      this.clearQuickCreate()
      this.selectedPosts = []
    },
    clearQuickCreate () {
      this.quickCreateCampaign = null
      this.quickCreateAdSet = null
      this.quickCreateCta = null
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
